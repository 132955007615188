import React from "react"

import Link from "../components/link"
import {BlogCard, BlogCardWide, BlogCardImage, CardHeader} from "../components/BlogCard"
import {ResponsiveGrid} from "../components/ResponsiveGrid"
import {ThemeContext, Box,  Paragraph, Text, Button, Heading,ResponsiveContext } from "grommet"

import Layout from "../components/layout"

import SEO from "../components/seo"


const IndexPage = ({ data }) => {

    const posts = data.posts.nodes;
    const [firstPost] = posts;

return (
  <Layout>

    <SEO title="Home" />

    <Box  margin={{top:"large"}} width="xlarge" >
      <Heading margin={{horizontal:"medium"}}>Giving Birth</Heading>
      
      <Paragraph margin={{horizontal:"medium"}}>
        When you are pregnant with your first child, giving birth is the great unknown. The good thing about it is that you have nine months to come to terms with all that it entails. We at Pregly want to give you the tools to better understand what happens to your body during labor and what you can do to aid in the process of the delivery.
      </Paragraph>

      <Paragraph margin={{horizontal:"medium"}}>
        We want you to feel empowered to make informed decisions about where and who you want to experience childbirth with. It is the start of you and your baby's life together. It deserves some thought and we are here to guide you through the many options you have.
      </Paragraph>
        
      <Paragraph margin={{horizontal:"medium"}}>
        One of the first questions your midwife will ask you is if you have thought about <Link to="/giving-birth/where-to-give-birth">where to have your baby</Link>. We've looked into some of the options available and the pros and cons for each one.
      </Paragraph>
      
      <Paragraph margin={{horizontal:"medium"}}>
        After the first three months, some women can't seem to curb their curiosity and begin reading books on birth. For this, we've compiled a useful [reading list](/giving-birth/childbirth-books) for you. If you think three months is a bit too early, you can always leave them on the shelf until you've passed the six month mark.
      </Paragraph>
      
      <Paragraph margin={{horizontal:"medium"}}>
        These books will cover in great detail what the signs of labor are, but if you don't feel like sifting through pages and pages to understand the process of childbirth, then check out our guide on the signs of labor.
      </Paragraph>
      
      <Paragraph margin={{horizontal:"medium"}}>
        Although there are few things that you have completely under control during childbirth, that shouldn't stop you from preparing yourself as best as you can. And one nice way to do so is to get your hospital bag in order.
        </Paragraph>

    </Box>

     <Box margin={{vertical:"medium"}}/>

     <Box  width="xlarge" margin={{vertical:"medium"}} border={{side:"top", color: "#FF63B1", size: "large" }}>
       <Heading  margin={{horizontal:"medium"}}>More about giving birth</Heading>
     </Box>

     <Box width="xlarge">
        <ResponsiveGrid gap="small" margin={{vertical:"small"}} columns="auto" rows="auto">
          {posts.map((post, idx) => (
            <BlogCard  post={post}/>
          ))}
        </ResponsiveGrid>
      </Box>

      <Box width="xlarge">
        <ResponsiveGrid gap="small" margin={{vertical:"small"}} columns="auto" rows="auto" >
            <BlogCard  post={firstPost}/>
            <BlogCardImage  post={firstPost}/>
        </ResponsiveGrid>
    </Box>

    <Box  width="xlarge" margin={{bottom:"large"}} align="start" justify="start" >
      <Button margin={{horizontal:"medium"}} color="black"  label="VIEW MORE" fill={false} href="/blog/"/>
    </Box>



    <Box margin={{vertical:"small"}}/>

     <Box  width="xlarge" margin={{vertical:"medium"}} border={{side:"top", color: "#FF4E48", size: "large" }}>
        <Heading margin={{horizontal:"medium"}}>What we're reading</Heading>
  </Box>

  <Box width="xlarge">
     <ResponsiveGrid gap="small" margin={{vertical:"small"}} columns="auto" rows="auto">
       {posts.map((post, idx) => (
         <BlogCard  post={post}/>
       ))}
     </ResponsiveGrid>
   </Box>

   <Box  width="xlarge" margin={{bottom:"large"}} align="start" justify="start" >
     <Button margin={{horizontal:"medium"}} color="black"  label="VIEW MORE" fill={false} />
   </Box>

  </Layout>
);};

export default IndexPage


export const query = graphql`
  {
    posts: allMdx(limit:3 ,filter: { frontmatter: { template: { eq: "blog" } } }) {
      nodes {
        id
        body
        excerpt(pruneLength: 80)
        frontmatter {
          featuredImage
          date
          author
          title
          category
          path
        }
      }
    }
  }
`;
